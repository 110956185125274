import React from 'react';
import Avatar from '@material-ui/core/Avatar';


const AvatarField = ({ record, source, size }) => {
    if (record === 'undefined' || typeof (record) === 'undefined') return <div></div>;
    if (record[source] === 'undefined' || typeof (record[source]) === 'undefined') return <div></div>;
    return <Avatar
        src={`${record[source]}?size=${size}x${size}`}
        size={size}
        style={{ width: size, height: size }}
    />;
}

AvatarField.defaultProps = {
    size: 25,
};


export const AvatarEmailField = ({ record = {}, source = 'photo_url', size }) => (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <AvatarField record={record} size={size} source={source} />
        &nbsp;{record.email}
    </div>
);

AvatarField.defaultProps = {
    size: 25,
};

export default AvatarField;