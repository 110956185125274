
import React, { Link, Component, Fragment } from 'react';
import { Pagination, SingleFieldList, ChipField, ReferenceArrayField, Labeled, ReferenceField, DateField, ReferenceManyField, Datagrid, TextField, Show, useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form'
import Divider from '@material-ui/core/Divider';
import { DocApproveButton, WalletResetButton, DocRejectButton, DeleteButton, AvatarButton, DriverSettingsButton } from '../../control/button/index';
import ActivateIcon from '@material-ui/icons/Check';
import NotActivateIcon from '@material-ui/icons/Clear';
import ImageViewer from '../../control/viewer/image-viewer';

import CardActions from '@material-ui/core/CardActions';

import {
    DataStatusField, DocStatusField, OrderStatusField, FormattedDateField, DriverStatusField, ActivityTypeField, AvatarField, FullNameClientField,
    ValidateField, ValidityDateField, DriverCompanyTypeField, FileCountField, ClientDataField, PhotoField, DocNameField, DocStatusReasonField, OrderTypeField
} from '../../control/field/index'
import {
    VehicleLockButton, VehicleUnlockButton, VehicleDateEditButton,
    VehicleApproveButton, VehicleRejectButton,
    DocDateEditButton, ValidateButton, CustomerLockButton, CustomerUnlockButton
} from '../../control/button/index'

import { Status, DataStatus, FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER } from '../../util/constant'
import { BACKEND_FILE_URL } from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';
import { getStripeConnectDueName, normalizeStripeConnectDues } from '../../util/function';
import moment from 'moment';
import DriverFieldEditButton from '../../control/button/driver-field-edit-button';
import DriverFieldRejectButton from '../../control/button/driver-field-reject-button';
import DriverFieldApproveButton from '../../control/button/driver-field-approve-button';
import DriverSettingsApproveButton from '../../control/button/driver-settings-approve-button';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', borderRadius: '50%' },


});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};





const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>

        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {typeof data != 'undefined' && typeof data.validated != 'undefined' && data.validated == FALSE &&
            <ValidateButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        }

        <DeleteButton resource="driver" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce chauffeur? Toutes les donnnées personnelles de ce chauffeur seront supprimées" />
        {/* <EditButton basePath={basePath} record={data} /> */}
        <ListButton basePath={basePath} />
        {/* <BackButton/> */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.show')}: {record ? `${record.email}` : ''}</span>;
};

const AvatarChangeButton = ({ record, basePath, resource }) => {
    return <AvatarButton basePath={basePath} record={record} resource={resource} />;
}

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            <ValidityDateField record={record} />
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            <ValidityDateField record={record} />
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record} />
                {record.date !== '' &&
                    <DocApproveButton record={record} />}</div>

        </div>
    )
};

const VehicleContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.docs;

    // files.forEach(function (file, index) {
    if (!record.file_url.startsWith("http://") && !record.file_url.startsWith("https://")) {
        record.file_url = BACKEND_FILE_URL + record.file_url;
        files.push(record.file_url);
    }
    // }
    // , files);

    if (files.length === 0)
        return <div>
            <ValidityDateField record={record} />
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            <ValidityDateField record={record} />
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record} /><DocApproveButton record={record} /></div>

        </div>
    )
};

// const VehicleDateField = ({ record = {}, label }) => {
//     const classes = useStyles();
//     return record.circulation_date !== "" ? <div><br />
//         <Typography variant="body2" className={classes.noColorLabel}>
//             Date de fin de validité: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label="" locales="fr-FR" /> : '--'}
//             <VehicleDateEditButton record={record} />
//         </Typography><br />
//     </div> : '';
// }
// className={classes.noColorLabel}
const BooleanField = ({ record = {}, source, label }) => {
    const classes = useStyles();
    const value = record[source] === "1" ? "Oui" : "Non";
    return <div>
        <Typography variant="body2">
            {value}€
        </Typography></div>;

}

BooleanField.defaultProps = {
    addLabel: true,
};

const WalletBalanceField = ({ record = { wallet: { balance: 0 } } }) => {
    const classes = useStyles();
    const value = record.wallet.balance;
    return <div>
        <Typography variant="body1" style={{ fontWeight: 600, fontSize: 15 }}>
            PORTEFEUILLE : {value}€
        </Typography><WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?" /></div>;

}

WalletBalanceField.defaultProps = {
    addLabel: false,
};

const WALLET_ACTION_END_CHARGE = 1;
const WALLET_ACTION_CANCEL_CHARGE = 2;
const WALLET_ACTION_CREATE_CHARGE = 3;
const WALLET_ACTION_TIPS = 4;
const WALLET_ACTION_RESET = 5;

const WalletActionField = ({ record = { action: 0 } }) => {
    const classes = useStyles();
    let text = "";
    if (record.action == WALLET_ACTION_END_CHARGE) text = "Frais de la course";
    else
        if (record.action == WALLET_ACTION_CANCEL_CHARGE) text = "Frais d'annulation";
        else
            if (record.action == WALLET_ACTION_CREATE_CHARGE) text = "Frais de création de la course";
            else
                if (record.action == WALLET_ACTION_TIPS) text = "Pourboire";
                else
                    if (record.action == WALLET_ACTION_RESET) text = "Remise à 0";
    return <div>
        <Typography variant="body2">
            {text}
        </Typography></div>;

}

WalletActionField.defaultProps = {
    addLabel: false,
};


// const AvatarChangeButton =  ({ record = {}, source,  label}) => {
//     return <AvatarButton user={record}/>;
// }

// AvatarChangeButton.defaultProps = {
//     addLabel: false,
// };


// const VehicleContentPanelX = ({ id, record, resource }) => {
//     const classes = useStyles();
//     console.log("Vehicle record " + JSON.stringify(record));
//     const transportInsuranceCertDate = record.transport_insuranc_cert_date;
//     const carStickerDate = record.car_sticker_date;

//     var transportInsuranceCertUrl = record.transport_insurance_cert_url;
//     // var hasTransportInsurance = transportInsuranceCertUrl != null && transportInsuranceCertUrl != "";
//     if (transportInsuranceCertUrl !== '' && !transportInsuranceCertUrl.startsWith("http://") && !transportInsuranceCertUrl.startsWith("https://"))
//         transportInsuranceCertUrl = BACKEND_FILE_URL + transportInsuranceCertUrl;

//     var carStickerUrl = record.car_sticker_url;
//     if (carStickerUrl !== '' && !carStickerUrl.startsWith("http://") && !carStickerUrl.startsWith("https://"))
//         carStickerUrl = BACKEND_FILE_URL + carStickerUrl;

//     var grayCardRectoUrl = record.gray_card_recto_url;
//     if (grayCardRectoUrl !== '' && !grayCardRectoUrl.startsWith("http://") && !grayCardRectoUrl.startsWith("https://"))
//         grayCardRectoUrl = BACKEND_FILE_URL + grayCardRectoUrl;

//     var grayCardVersoUrl = record.gray_card_verso_url;
//     if (grayCardVersoUrl !== '' && !grayCardVersoUrl.startsWith("http://") && !grayCardVersoUrl.startsWith("https://"))
//         grayCardVersoUrl = BACKEND_FILE_URL + grayCardVersoUrl;

//     var frontImageUrl = record.front_image_url;
//     if (frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
//         frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

//     // var rearImageUrl = record.rear_image_url;
//     // if(rearImageUrl !== '' && !rearImageUrl.startsWith("http://") && !rearImageUrl.startsWith("https://"))
//     //     rearImageUrl = BACKEND_FILE_URL + rearImageUrl;

//     // var luggageBoxUrl = record.luggage_box_url;
//     // if(luggageBoxUrl !== '' && !luggageBoxUrl.startsWith("http://") && !luggageBoxUrl.startsWith("https://"))
//     //     luggageBoxUrl = BACKEND_FILE_URL + luggageBoxUrl;

//     // var wholeImageUrl = record.whole_image_url;
//     // if(wholeImageUrl !== '' && !wholeImageUrl.startsWith("http://") && !wholeImageUrl.startsWith("https://"))
//     //     wholeImageUrl = BACKEND_FILE_URL + wholeImageUrl;

//     // var insideImageUrl = record.inside_image_url;
//     // if(insideImageUrl !== '' && !insideImageUrl.startsWith("http://") && !insideImageUrl.startsWith("https://"))
//     //     insideImageUrl = BACKEND_FILE_URL + insideImageUrl;

//     var files = [];
//     files[0] = { file_url: grayCardRectoUrl, name: 'Carte grise (recto)' };
//     files[1] = { file_url: grayCardVersoUrl, name: 'Carte grise (verso)' };
//     files[2] = { file_url: transportInsuranceCertUrl, name: "Attestation d’assurance de transport de personnes à titre onéreux" };
//     files[3] = { file_url: carStickerUrl, name: "Macaron" };
//     files[4] = { file_url: frontImageUrl, name: 'Photo face avant du véhicule (plaque visible)' };
//     // files[3] = {file_url: rearImageUrl, name: 'Photo face arrière du véhicule'};



//     // files[5] = {file_url: wholeImageUrl, name: 'Photo du véhicule en largeur mode paysage'};
//     // files[6] = {file_url: insideImageUrl, name: 'Photo intérieure arrière du véhicule'};
//     // files[7] = {file_url: luggageBoxUrl, name: 'Photo du coffre'};



//     return (

//         <div style={styles.flexColumn}>
//             {/* <span>Couleur : {record['color']}</span><br/>
//                 <span>Nombre de siège passager max. : {record['seats']}</span><br/> */}
//             {/* <span>Nombre de bagage max. : {record['luggages']}</span><br/> */}
//             {/* <span>Litrage du coffre : {record['luggage_volume']}</span><br/> */}

//             {/* <span>Motorisation (Cheveaux fiscaux) : {record['horse_power']}</span><br/> */}
//             {/* <ReferenceField label="Modèle" 
//                         source="model_id" reference="vehicle_model"
//                         perPage={9999999}>
//                             <TextField source="name" />
//                         </ReferenceField> */}
//             <div style={styles.images}>
//                 {files.map(file => {
//                     return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300' />
//                 })}
//             </div>
//             {/* <VehicleDateField record={record}/> */}
//             <div className={classes.noColorLabel}><span>Actions: </span>
//                 {console.log("Vehicle is locked = " + record.is_locked)}
//                 {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record} />}
//                 {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record} />}
//                 {record.status == DataStatus.NEW &&
//                     <VehicleApproveButton record={record} />}
//                 {record.status == DataStatus.NEW &&
//                     <VehicleRejectButton record={record} />}
//             </div>
//         </div>
//     );
// };

const ContentTextField = ({ source, record = {}, label }) => {
    if (typeof (record) !== 'undefined') {
        var content = record['content'];
        if (typeof (content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig, "");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}

ContentTextField.defaultProps = {
    addLabel: true,
};

// const CompanyField = ({ source, record = {}, label}) => {
//     const classes = useStyles();
//     if(typeof(record) !== 'undefined'){
//         var userType = record['user_type'];
//         if(typeof(userType) !== 'undefined' && userType !== null){
//             if(userType === USER_TYPE_COMPANY_AND_DRIVER){
//                 return <div>
//                 <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Société</Typography>
//             <Labeled label="Nom de société">
//         <Typography variant="body2">
//             {record.company_name}
//         </Typography>
//         </Labeled>
//         <Labeled label="Adresse du siègle social">
//         <Typography variant="body2">
//             {record.company_address}
//         </Typography>
//         </Labeled>

//         <Labeled label="Code postal">
//         <Typography variant="body2">
//             {record.postal_code}
//         </Typography>
//         </Labeled>

//         <Labeled label="Ville">
//         <Typography variant="body2">
//             {record.city}
//         </Typography>
//         </Labeled>
//         <br/>
//         <Labeled label="IBAN">
//         <Typography variant="body2">
//             {record.iban}
//         </Typography>
//         </Labeled>
//         <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Représentant légal</Typography>
//         <Labeled label="Nom">
//         <Typography variant="body2">
//             {record.manager !== null && record.manager.last_name !== null ? record.manager.last_name: ""}
//         </Typography>
//         </Labeled>

//         <Labeled label="Prénom">
//         <Typography variant="body2">
//             {record.manager !== null && record.manager.first_name !== null ? record.manager.first_name : ""}
//         </Typography>
//         </Labeled>
//         <br/>
//         <Labeled label="Date de naissance">
//         <Typography variant="body2">
//             {record.manager !== null && record.manager.birth_date !== null ? record.manager.birth_date : ""}
//         </Typography>
//         </Labeled>

//         <br/>
//         <Labeled label="Adresse">
//         <Typography variant="body2">
//             {record.manager !== null && record.manager.address !== null ? record.manager.address : ""}
//         </Typography>
//         </Labeled>
//         <br/>
//         <Labeled label="Pays">
//         <Typography variant="body2">
//             {record.manager !== null && record.manager.country_name !== null ? record.manager.country_name : "Belgique"}
//         </Typography>
//         </Labeled>

//               </div>;
//             }

//             return <div>

//     <Labeled label="Date de naissance">
//         <Typography variant="body2">
//             {record.birth_date}
//         </Typography>
//         </Labeled>

//         <Labeled label="Pays">
//         <Typography variant="body2">
//             {record.country_name}
//         </Typography>
//         </Labeled>
//         <br/>
//         <Labeled label="Adresse">
//         <Typography variant="body2">
//             {record.address}
//         </Typography>
//         </Labeled>
//         <br/>
//         <Labeled label="Code postal">
//         <Typography variant="body2">
//             {record.postal_code}
//         </Typography>
//         </Labeled>

//         <Labeled label="Ville">
//         <Typography variant="body2">
//             {record.city}
//         </Typography>
//         </Labeled>
//         <br/>
//         <Labeled label="IBAN">
//         <Typography variant="body2">
//             {record.iban}
//         </Typography>
//         </Labeled>
//             </div>;
//         }

//     }
//     return <span></span>
// }

// CompanyField.defaultProps = {
//     addLabel: false,
// };

const CoeffFields = ({ record }) => {
    // const classes = useStyles();
    // console.log("Vehicle record " + JSON.stringify(record));
    if (record === null || record === 'undefined') return <div />;
    return (

        <div style={styles.flexColumn}>
            <br />
            <p><span style={{ 'font-size': '14px' }}>&emsp;Prix par km (€/km): {record['price_km']}</span></p>
            <p><span style={{ 'font-size': '14px' }}>&emsp;Prix par minute (€/minute): {record['price_minute']}</span></p>
            <p><span style={{ 'font-size': '14px' }}>&emsp;Prix minimum (€): {record['price_min']}</span></p>
            <br />

        </div>
    );
};


// const CardNumberField = ({ record, label }) => {
//     // const classes = useStyles();
//     // const data = JSON.parse(record.data);
//     console.log('Card ' + JSON.stringify(record));
//     console.log('Car data ' + JSON.stringify(record.data));
//     return <div><span>{'xxxx xxxx xxxx ' + record.data.last4}</span></div>
//     // return <div></div>
// }

// const ExpiryField = ({ record, label }) => {
//     // const classes = useStyles();
//     // const data = JSON.parse(record.data);
//     //    console.log('Card ' + JSON.stringify(record));
//     //  console.log('Car data ' + JSON.stringify(record.data));
//     if (typeof (record) !== 'undefined' && typeof (record.data) !== 'undefined')
//         return <div><span>{record.data.expiry_month + '/' + record.data.expiry_year}</span></div>
//     return <div></div>
// }

// // const userId = localStorage.getItem('user_id');
// // const role = localStorage.getItem('role');
// const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

// import { withStyles } from "material-ui/styles";
const StripeConnectField = ({ id, record, resource }) => {
    const classes = useStyles();
    if (typeof (record.stripe_account) === 'undefined' || record.stripe_account === 'undefined' || record.stripe_account === null || record.stripe_account === "")
        return (<div style={styles.flexColumn}>
            <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Etat du compte Stripe</Typography>
            <br />
            <div>Aucun compte</div>

        </div>);
    var account = JSON.parse(record.stripe_account);
    console.log("Stripe account " + JSON.stringify(account));
    if (typeof (account) === 'undefined' || account === 'undefined' || account === null)
        return (<div style={styles.flexColumn}>
            <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Etat du compte Stripe</Typography>
            <br />
            <div>Aucun compte</div>

        </div>);
    var eventuallyDues = [];
    console.log("Stripe account type " + account.business_type);
    if (account.business_type === 'individual') {
        var individual = account.individual;
        if (typeof (individual) !== 'undefined' && individual !== 'undefined' && individual !== null) {
            eventuallyDues = individual.requirements.eventually_due;
            eventuallyDues = normalizeStripeConnectDues(eventuallyDues);
        }
    }
    else {
        // var company = account.company;
        // if(typeof(company) !== 'undefined' && company !== 'undefined' && company !== null){
        eventuallyDues = account.requirements.eventually_due;
        eventuallyDues = normalizeStripeConnectDues(eventuallyDues);
        // }

    }

    // requirements.forEach(function(requirement, index){
    //     if(!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")){
    //         file.file_url = BACKEND_FILE_URL + file.file_url;
    //         files[index] = file;
    //     }
    // }
    // , files);

    // if(eventuallyDues.length === 0)
    //     return account.charges_enabled && account.payouts_enabled ? <div>Transfert activé - Paiement activé
    //     </div> : account.charges_enabled && !account.payouts_enabled ? <div>Transfert activé - Paiement desactivé</div> : <div>Transfert desactivé - Paiement desactivé</div>;

    return (
        <div style={styles.flexColumn}>
            <div><Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Etat du compte Stripe</Typography>
                <StripeConnectStatusField record={record} /></div>

            {account.charges_enabled && account.payouts_enabled && <div>Transfert activé - Paiement activé</div>}
            {account.charges_enabled && !account.payouts_enabled && <div>Transfert activé - Paiement désactivé</div>}
            {!account.charges_enabled && !account.payouts_enabled && <div>Transfert désactivé - Paiement désactivé</div>}


            <br />
            {eventuallyDues.length > 0 && <Typography variant="body1">Informations à compléter:</Typography>}
            <br />
            <div>
                {eventuallyDues.map(eventuallyDue => {
                    return <div><span><Typography variant="body1"> - {getStripeConnectDueName(eventuallyDue)}</Typography></span></div>;
                })}
                <br />
            </div>
            {/* <ValidityDateField record={record}/>
            <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>
    )
};

const StripeConnectStatusField = ({ id, record, resource }) => {
    const classes = useStyles();
    if (typeof (record.stripe_account) === 'undefined' || record.stripe_account === 'undefined' || record.stripe_account === null || record.stripe_account === "")
        return <NotActivateIcon color='disabled' />

    var account = JSON.parse(record.stripe_account);
    console.log("Stripe account " + JSON.stringify(account));
    if (typeof (account) === 'undefined' || account === 'undefined' || account === null)
        return <NotActivateIcon color='disabled' />;
    var eventuallyDues = [];
    console.log("Stripe account type " + account.business_type);
    if (account.business_type === 'individual') {
        var individual = account.individual;
        if (typeof (individual) !== 'undefined' && individual !== 'undefined' && individual !== null) {
            eventuallyDues = individual.requirements.eventually_due;
        }
    }
    else {
        // var company = account.company;
        // if(typeof(company) !== 'undefined' && company !== 'undefined' && company !== null){
        eventuallyDues = account.requirements.eventually_due;
        // }

    }

    if (eventuallyDues.length > 0)
        return <NotActivateIcon color='disabled' />;
    return <ActivateIcon color='primary' />;
};

StripeConnectStatusField.defaultProps = {
    addLabel: true,
};

const ManagerBirthDateField = ({ record = {}, source, label, locales, classes, format }) => {
    // const classes = useStyles();
    const translate = useTranslate();

    if (typeof (record) === 'undefined' ||
        typeof (record['manager']) === 'undefined' || record['manager'] === null
        || record[source] === "")
        return <Typography variant="body2">---</Typography>

    var manager = record['manager'];
    if (typeof (manager) === 'undefined' || manager === null ||
        typeof (manager['birth_date']) === 'undefined' || manager['birth_date'] === null
        || record[source] === "")
        return <Typography variant="body2">---</Typography>

    if (typeof (format) === 'undefined' ||
        format === 'undefined' || format === '')
        format = 'format_date';

    const value = moment(manager['birth_date'], "YYYY-MM-DD HH:mm:ss");
    return <Typography variant="body2">{value.format(translate(format))}</Typography>

}



ManagerBirthDateField.defaultProps = {
    addLabel: true,
};

const IbanActionWidget = ({ record = {}, }) => {
    const classes = useStyles();

    return <div className={classes.flex}>
        {/* Actions: */}
        {record.iban !== "" && record.iban !== 'undefined' && record.iban !== null && record.iban_status == DataStatus.NEW &&
            <DriverFieldRejectButton record={record} fieldName="iban" fieldLabel="IBAN" />}
        {record.iban !== "" && record.iban !== 'undefined' && record.iban !== null && record.iban_status == DataStatus.NEW &&
            <DriverFieldApproveButton record={record} fieldName="iban" fieldLabel="IBAN" />}
        {record.iban !== "" && record.iban !== 'undefined' && record.iban !== null && record.iban_status == DataStatus.REJECTED &&
            <DriverFieldApproveButton record={record} fieldName="iban" fieldLabel="IBAN" />}
    </div>;

}



IbanActionWidget.defaultProps = {
    addLabel: false,
};

const ValidateAttentionField = ({ record = {}, }) => {
    const classes = useStyles();

    if (record.validated !== "1" && record.validated !== 1)
        return <Typography style={{ fontWeight: 400, textDecorationLine: 'underline', fontSize: 14, color: 'red' }}>Ce compte n'est pas encore validé</Typography>
    // return <span>Attention : Ce compte n'est pas encore validé</span>;
    return <span />;
}



ValidateAttentionField.defaultProps = {
    addLabel: false,
};


// const PriceKmField = ({ record = {price_km: 0}}) => {
//     const classes = useStyles();
//     const value = record.wallet.balance;
//     return <div>

//             <Typography variant="body1" style={{ fontWeight: 600, fontSize:15 }}>
//             Solde du wallet : {value} points

//         </Typography>
//         <br/>
//         <WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet à 0 ?"/>

//         <WalletEditButton record={record} /></div>;

// }

// WalletBalanceField.defaultProps = {
//     addLabel: false,
// };


const ShowDriver = ({ staticContext, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (

        <Show component="div" title={<Title />} actions={<ShowActions />} {...props}>
            <TabbedShowLayout redirect="list">
                <Tab label="Profil">
                    {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Profil</Typography> */}
                    <ValidateAttentionField />
                    <DriverStatusField source="online_status" label="Statut" />
                    {/* <AvatarField source="photo_url" label="" size='200'/> */}
                    {/* <TextField source="user_code" label="Identifiant parrain"/> */}
                    {/* <TextField source="company_name" label="Nom de la société"/> */}
                    {/* <DriverCompanyTypeField label="Type de chauffeur"/> */}
                    <PhotoField source="photo_url" label="" />
                    {/* <div style={{"width":"60px" , "height":"60px"}}> */}
                    {/* <ImageField source="photo_url" label="" className="avatar"/> */}
                    {/* </div> */}
                    {/* <ImageField source="photo_url" label="" style={{"width":"60px" , "height":"60px", "border-radius": "50%"}}/> */}
                    {/* <AvatarChangeButton/> */}
                    {/* <TextField source="user_code" label="Identifiant unique"/> */}
                    {/* <TextField source="id" label="ID"/> */}
                    {/* <GenderField label={translate('civility')}/> */}
                    {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField> */}
                    <div />
                    <TextField source="user_code" label="Identifiant parrainage" />
                    <TextField source="reg_code" label="Code parrainage" />
                    <TextField source="last_name" label="Nom" className={classes.inlineBlock} />
                    <TextField source="first_name" label="Prénom " className={classes.inlineBlock} />
                    <div />

                    <div />
                    <TextField source="full_mobile" label="Mobile" className={classes.inlineBlock} />
                    <TextField source="email" label="Email" className={classes.inlineBlock} />
                    <div />
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                    {/* <TextField source="address" label="Adresse" className={classes.inlineBlock}/>
            <TextField source="postal_code" label="Code postal" className={classes.inlineBlock}/>
            <TextField source="city" label="Ville" className={classes.inlineBlock}/> */}
                    {/* <TextField source="country_name" label="Pays" className={classes.inlineBlock}/> */}
                    <ValidateField source="activated" label='Activé ?' />
                    <TextField source="activation_code" label="Code d'activation" />
                    <ValidateField source="validated" label='Validé ?' />
                    <ValidateField source="locked" label='Verrouillé ?' />

                    <TextField source="mode" label="Mode Stripe" className={classes.inlineBlock} />
                    <ValidateField source="stripe_validated" label='Validé par Stripe' className={classes.inlineBlock} />

                    <div />
                    <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
                    <FormattedDateField source="update_time" format="format_date_time" label="Profil modifié" locales="fr-FR" />

                    {/* <TextField source="siren_number" label="Numéro SIRET"/>
            <TextField source="iban" label="IBAN"/> */}

                    {/* <ActivityTypeField source="activity_type" label = "Type d'activité"/>
                <ValidateField source="animal_accepted" label="Animal accepté"/> */}
                    {/* <TextField source="wallet.balance" label="Portefeuille(€)" className={classes.inlineBlock}/> */}
                    {/* <br/> */}
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}

                    {/* <br/>
                <Divider/> */}
                    {/* <br/> */}
                    {/* <Typography variant="body2">État</Typography> */}

                </Tab>

                <Tab label="Infos de société">
                    <ValidateAttentionField />
                    {/* <AvatarField source="photo_url" label="" size='80'/> */}
                    {/* <PhotoField source="photo_url" label=""/>
                <TextField source="id" label="ID"/> */}
                    {/* <GenderField label={translate('civility')}/> */}
                    {/* <ReferenceField label="Nom de société" source="company_id" reference="driver_company" link="show">
                    <TextField source="company_name" />
                </ReferenceField>
                <div/> */}
                    {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Société</Typography> */}

                    <DriverCompanyTypeField label="Type de société" />
                    <TextField source="company_name" label="Nom de l'entreprise" />
                    <DriverFieldEditButton fieldName="company_name" fieldLabel="Nom de l'entreprise" />
                    <TextField source="company_address" label="Adresse du siège social " />
                    <TextField source="postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="city" label="Ville" className={classes.inlineBlock} />
                    <TextField source="country_name" label="Pays" className={classes.inlineBlock} />
                    {/* <TextField source="phone" label = "Téléphone"/> */}
                    <TextField source="tax_id" label="Nº identification fiscale" />
                    <TextField source="siren_number" label="Nº de SIRET" />
                    <DriverFieldEditButton fieldName="siren_number" fieldLabel="Nº de SIRET" />

                    {/* <div className={classes.flex}> */}

                    <TextField source="iban" label="IBAN" />
                    {/* <Fragment>
                        <div className={classes.flex}> */}
                    <DataStatusField source="iban_status" valueField="iban" label="État de l'IBAN" />
                    <IbanActionWidget />
                    {/* </div>
                    </Fragment> */}
                    {/* </div> */}




                    {/* <TextField source="vat_no" label="Nº de TVA intracommunautaire" /> */}
                    <br />
                    {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Représentant légal</Typography>
                    <TextField source="manager.last_name" label="Nom" className={classes.inlineBlock} />
                    <TextField source="manager.first_name" label="Prénom" className={classes.inlineBlock} />
                    <div />
                    <ManagerBirthDateField label="Date de naissance" locales="fr-FR" />
                    
                    <div />
                    <TextField source="manager.address" label="Adresse" className={classes.inlineBlock} />
                    <TextField source="manager.city" label="Ville" className={classes.inlineBlock} />
                    <TextField source="manager.postal_code" label="Code postal" className={classes.inlineBlock} />
                    <div /> */}
                    {/* <TextField source="manager.country_code" label = "Pays"/> */}

                    {/* <TextField source="email" label = "Email" className={classes.inlineBlock}/> */}
                    {/* <br/> */}
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                    {/* <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/> */}
                    {/* <br/>
                <Divider/> */}
                    {/* <br/> */}
                    {/* <Typography variant="body2">État</Typography> */}
                    {/* <ValidateField source="activated" label='Activé' className={classes.inlineBlock}/>
                <ValidateField source="validated" label='Validé' className={classes.inlineBlock}/>
                <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/> */}
                </Tab>

                <Tab label="Compte Stripe">
                    <StripeConnectField />
                </Tab>

                <Tab label="Configuration des prix">
                    {/* <BooleanField source="settings.3rd_payment" label="Tiers payant"/> */}
                    <CoeffFields />
                    <DriverSettingsButton />
                    <DriverSettingsApproveButton />
                </Tab>

                <Tab label="Documents d'activité">
                    <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                        <Datagrid expand={<DocContentPanel />} expandHeader={<div />}>
                            {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                            <DocNameField label="Nom du document" />
                            <FileCountField label="Fichiers" />
                            <DocStatusField source="status" label="État" />
                            <DocStatusReasonField label="Motif de refus" />
                            {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                            {/* <OpenFileButton/> */}

                        </Datagrid>
                    </ReferenceManyField>
                    {/* <FileField source="files" src='url' title='title' label="Fichiers" target="_blank"/> */}
                    {/* <FileViewer source="files" src='url' /> */}
                </Tab>
                <Tab label="Véhicules">
                    <ReferenceManyField pagination={<Pagination />} reference="vehicle" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <TextField source="plate_number" label="Plaque" className={classes.colorValue} />
                            {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Modèle" 
                        source="model_id" reference="vehicle_model"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField> */}

                            <TextField source="brand_name" label="Marque" />
                            <TextField source="model_name" label="Modèle" />
                            {/* <TextField source="vehicle_type_name" label="Type"/> */}
                            {/* <TextField source="color" label="Couleur"/> */}
                            {/* <TextField source="color" label="Couleur"/> */}
                            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                                <TextField source="name" />
                            </ReferenceField>
                            {/* <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de vêtement siège" source="seat_cover_type_id" reference="seat_cover_type" link="">
                            <TextField source="name" />
                        </ReferenceField>

                        <ReferenceArrayField label="Options" reference="option" source="services">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField> */}
                            <TextField source="color" label="Couleur" />
                            <TextField source="seats" label="Nombre de passagers max." />
                            <TextField source="luggages" label="Nombre de bagages max." />
                            <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" />
                            <FormattedDateField source="control_date" label="Date du prochain contrôle technique" locales="fr-FR" />

                            <ValidateField source="is_default" label="Par défaut" />
                            <DataStatusField source="status" label="" />
                            <ValidateField source="is_locked" label="Vérrouillé" />

                            {/* <VehicleDateEditButton/> */}
                        </Datagrid>
                    </ReferenceManyField>

                </Tab>

                <Tab label="Commandes">
                    <ReferenceManyField pagination={<Pagination />} reference="order" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <TextField source="create_time" label="Date\heure" />
                            <TextField source="order_number" label="Nº de commande" />
                            <OrderTypeField source="order_type" label="Type de commande" />
                            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                                <TextField source="name" />
                            </ReferenceField>
                            {/* <FullNameClientField label="Client"/> */}
                            <ReferenceField label="Client" source="client_id" reference="client" link=""
                                perPage={9999999}>
                                <TextField source="email" />
                            </ReferenceField>
                            {/* <ClientDataField source="last_name" label="Nom du client"/> */}
                            {/* <ClientDataField source="first_name" label="Prénom du client"/> */}
                            <TextField source="from_address" label="Adresse de départ" />
                            <TextField source="to_address" label="Adresse d'arrivée" />
                            {/* <Typography style={{ fontWeight: 500, textDecorationLine: 'underline', fontSize:12 }}>Arrêts</Typography>
                <ReferenceManyField reference="order_stop" target="order_id" addLabel={false}>
                    
                    <Datagrid>
                        <TextField label="Adresse" source="address"/> */}
                            {/* <StopStatusTextField label="État" source="status"/> */}
                            {/* </Datagrid> */}
                            {/* </ReferenceManyField> */}
                            <TextField source="total" label="Prix(€)" />
                            <OrderStatusField source="status" label="État" />
                            {/* <TextField source="rating_on_driver" label="Note attribué par le client"/> */}
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Parrainages">
                    <Typography variant="body2">Filleuls</Typography>
                    <ReferenceManyField style={{ 'width': '50%' }} pagination={<Pagination style={{ 'width': '80%' }} />} reference="driver_parrain" target="driver_id" filter={{ 'type': "child" }} addLabel={false}>
                        <Datagrid rowClick="">
                            <AvatarField source="photo_url" label="" size='200' />
                            <TextField source="email" label="Email" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="last_name" label="Nom" />
                            <TextField source="order_count" label="Nombre de courses complétées" />
                            <TextField source="amount" label="Bonus à réserver (€)" />
                            <TextField source="remain_days" label="Jours restants" />
                        </Datagrid>
                    </ReferenceManyField>

                    <Typography variant="body2">Parrains</Typography>
                    <ReferenceManyField style={{ 'width': '40%' }} pagination={<Pagination />} reference="driver_parrain" target="driver_id" filter={{ 'type': "parent" }} addLabel={false}>
                        <Datagrid rowClick="">
                            <AvatarField source="photo_url" label="" size='200' />
                            <TextField source="email" label="Email" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="last_name" label="Nom" />

                        </Datagrid>
                    </ReferenceManyField>

                    <Typography variant="body2">Clients</Typography>
                    <ReferenceManyField style={{ 'width': '40%' }} pagination={<Pagination />} reference="driver_parrain" target="driver_id" filter={{ 'type': "client" }} addLabel={false}>
                        <Datagrid rowClick="">
                            <AvatarField source="photo_url" label="" size='200' />
                            <TextField source="email" label="Email" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="last_name" label="Nom" />

                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Statistiques">
                    {/* <Typography variant="body2">En construction</Typography> */}
                    <TextField source="statistics.cancel_rate" label="Taux d'annulation (%)" />
                    <TextField source="statistics.accept_rate" label="Taux d'acceptation (%)" />
                    <TextField source="statistics.completed" label="Courses complétées" />
                    <TextField source="statistics.parrain_client" label="Nombre de clients parrainés" />
                </Tab>
            </TabbedShowLayout>


        </Show>
    );
};


export default ShowDriver;

